import {i18n} from 'i18n';

import {UNKNOWN_MODEL_ID} from '~constants/constants';
import {theme} from '~constants/theme';
import {AnyLabelledItem, getAliasFromItems, getColorFromItems, getUUID} from '~utils/miscUtils';
import {
  AnnotationLabel,
  Coordinate,
  ImageDataAnnotation,
  LabelType,
  PolygonOrClassAnnotationLabel,
} from '~redux/types/images';
import {Model} from '~redux/types/models';
import {PartialWithRequired} from '~redux/types/utils';
import {AnnotationItem, AnnotationItemsByModel} from '~redux/reducers/annotatorReducer';

import {isPolygonAnnotationLabel} from '~components/images/utils/annotation.utils';
import {isClassificationLabel, isPolygonLabel} from '~components/images/utils/classification.utils';
import {getInspectionType} from '~components/models/models.utils';

export function getAnnotationLabelType(label: AnnotationLabel): LabelType {
  return isPolygonAnnotationLabel(label) ? 'polygon' : 'class';
}

function coordinatesToString(coordinates: Coordinate[]): string {
  return coordinates.map((c) => `${c.x}${c.y}`).join('');
}

export function offsetCoordinates(coordinates: Coordinate[], offsetX: number = 0, offsetY: number = 0): Coordinate[] {
  return coordinates.map((c) => ({x: c.x + offsetX, y: c.y + offsetY}));
}

/**
 * Returns a unique ID for a polygon or class annotation label. If the label already has an ID (polygonId), it will be returned.
 */
export function getAnnotationItemId(label: PolygonOrClassAnnotationLabel, isMachinePrediction: boolean): string {
  const isPolygon = isPolygonLabel(label);
  const machinePredictionPrefix = isMachinePrediction ? 'machine' : '';
  if (isPolygon && 'polygonId' in label && label.polygonId) {
    return label.polygonId;
  } else if (isPolygon) {
    return machinePredictionPrefix + label.type + label.id + coordinatesToString(label.coordinates);
  } else if (isClassificationLabel(label)) {
    return machinePredictionPrefix + 'class' + label.id;
  }
  return getUUID();
}

interface CreateAnnotationItemArgs {
  annotationLabel: AnnotationLabel;
  imageDataAnnotation: ImageDataAnnotation;
  isMachinePrediction: boolean;
  labelItems: AnyLabelledItem[];
  models: Model[];
}

export const isSimilarityLabel = (labelId: string) => labelId.startsWith('SIMILARITY');

const getAnnotationColor = (isUnknownMachinePrediction: boolean, labelId: string, labelItems: AnyLabelledItem[]) => {
  if (isSimilarityLabel(labelId)) {
    return theme.palette.maddox.similarityLabel;
  }

  if (isUnknownMachinePrediction) return theme.palette.maddox.black40;

  return getColorFromItems(labelId, labelItems);
};

export function createAnnotationItem({
  annotationLabel,
  isMachinePrediction,
  imageDataAnnotation,
  labelItems,
  models,
}: CreateAnnotationItemArgs): AnnotationItem {
  const {id: labelId, coordinates, probability, hiddenForSmartSession} = annotationLabel;
  const {modelId, updatedAt, user, isDefaultPrediction} = imageDataAnnotation;

  const userEmail = isMachinePrediction ? i18n.t('maddoxAIMachinePrediction') : user;
  const isUnknownMachinePrediction = isMachinePrediction && modelId === UNKNOWN_MODEL_ID;
  const color = getAnnotationColor(isUnknownMachinePrediction, labelId, labelItems);

  let updatedModelId: string | undefined = modelId;
  if (isMachinePrediction && modelId === UNKNOWN_MODEL_ID) {
    updatedModelId = modelId === UNKNOWN_MODEL_ID ? undefined : modelId;
  }

  return {
    changeType: 'none',
    labelId,
    modelId: updatedModelId,
    userEmail,
    updatedAt,
    isMachinePrediction,
    isUnknownMachinePrediction,
    isDefaultPrediction: isDefaultPrediction ?? false,
    color,
    id: getAnnotationItemId(annotationLabel, isMachinePrediction),
    type: getAnnotationLabelType(annotationLabel),
    alias: getAliasFromItems(labelId, labelItems),
    inspectionType: getInspectionType(annotationLabel.id, models),
    coordinates: coordinates ?? undefined,
    probability: probability ?? undefined,
    hiddenForSmartSession: hiddenForSmartSession,
  };
}

export function getAnnotationItemById(
  annotationItemId: AnnotationItem['id'],
  annotationItems: AnnotationItem[],
): AnnotationItem | undefined {
  return annotationItems.find((item) => item.id === annotationItemId);
}

export function getModelIdForAnnotationItem(
  annotationItemId: AnnotationItem['id'],
  annotationItemsByModel: AnnotationItemsByModel,
): string | undefined {
  let modelId: string | undefined;
  Object.entries(annotationItemsByModel).forEach(([currentModelId, items]) => {
    if (items.find((item) => item.id === annotationItemId)) {
      modelId = currentModelId;
    }
  });
  return modelId;
}

export function updateAnnotationItem(
  itemToUpdate: PartialWithRequired<AnnotationItem, 'id'>,
  annotationItems: AnnotationItem[],
): AnnotationItem[] {
  const existingItem = getAnnotationItemById(itemToUpdate.id, annotationItems);
  if (!existingItem || existingItem.isMachinePrediction) {
    return annotationItems;
  }

  // New items should stay "new" even if they are modified
  const changeType = itemToUpdate.changeType === 'new' ? 'new' : 'modified';
  return annotationItems.map((item) => {
    if (item.id === itemToUpdate.id) {
      return {
        ...item,
        ...itemToUpdate,
        changeType,
        updatedAt: Date.now(),
      };
    }
    return item;
  });
}

export function deleteAnnotationItem(annotationItemId: string, annotationItems: AnnotationItem[]): AnnotationItem[] {
  const itemToDelete = getAnnotationItemById(annotationItemId, annotationItems);
  if (!itemToDelete || itemToDelete.isMachinePrediction) {
    return annotationItems;
  }

  // delete the annotation item if it was previously "new"
  if (itemToDelete.changeType === 'new') {
    annotationItems = annotationItems.filter((item) => item.id !== itemToDelete.id);
  }

  // otherwise set the changeType to "deleted" if it was not previously "new"
  return annotationItems.map((item) => {
    if (item.id === itemToDelete.id) {
      return {
        ...item,
        changeType: 'deleted',
      };
    }
    return item;
  });
}

/**
 * Adds an annotation item to the list of existing annotation items.
 *
 * Within the same model, an image can either have one classification item or one or more polygon items, but not both. That means:
 * - when adding a new classification, we must delete all items of the same model and then add the new classification
 * - when adding a new polygon, we must delete all human classification items for the same model
 *
 * In either case, we must keep machine predictions as they are.
 */
export function addAnnotationItem(
  itemToAdd: AnnotationItem,
  annotationItems: AnnotationItem[] | undefined,
): AnnotationItem[] {
  const updatedExistingItems: AnnotationItem[] = [];

  if (!annotationItems) {
    return [itemToAdd];
  }

  for (const item of annotationItems) {
    if (item.isMachinePrediction) {
      updatedExistingItems.push(item);
      continue;
    }

    if (item.type === 'class' && item.changeType !== 'new') {
      // get rid of all existing classification items - there can only be one classification item per model.
      // By checking for changeType !== 'new', we make sure that new items are fully deleted
      // (by not adding them to the updatedItems)
      updatedExistingItems.push({
        ...item,
        changeType: 'deleted',
      });
    } else if (itemToAdd.type === 'polygon' && item.type === 'polygon') {
      // when adding a polygon, keep other polygons as they are
      updatedExistingItems.push(item);
    }
  }

  return [...updatedExistingItems, itemToAdd];
}
