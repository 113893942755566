import {LabelItem} from '~redux/types/images';

export interface ReduxStateModels {
  currentModel: Model | undefined;
}

// IMPORTANT: the enum values are defined by the API, don't change them unless the API is updated
export enum InspectionType {
  // NOTE: when updating the values here translation keys need to be updated as well
  // The order here influences the order in which the inspection types are displayed in the creation wizard
  classification = 'classification',
  surfaceInspection = 'surface_inspection',
  objectDetection = 'object_detection',
  anomalyDetection = 'anomaly_detection',
  legacyModel = 'legacy_model',
}

interface ModelMetrics {
  accuracy: number; // Range: [0, 1]
  datasetId: 'complete' | 'test'; // DatasetType enumeration
  escapeRate: number; // Range: [0, 1]
  f1Score: number; // Range: [0, 1]
  lastPublishedModelTs: number; // Date-time
  overkillRate: number; // Range: [0, 1]
  thresholdCombination: {
    areaSize: number; // Integer value greater than or equal to 0
    confidence: number; // Float value between 0 and 1
  };
  interpretation: Interpretation;
}

export type Model = {
  id: string;
  name: string;
  createdAt: number;
  inspectionType: InspectionType;
  labels: LabelItem[]; // the error class labels
  defaultMachineLabel?: string | null;
  automlEnabled: boolean;
  automlPipelineStatusData?: AutoMLPipelineStatusResponse | null;
  imageCount: number;
  humanAnnotationCount: number;
  latestPublishedModelMetrics?: ModelMetrics[] | null;
  latestTrainedModelMetrics?: ModelMetrics[] | null;
  latestPipeline?: LatestPipelineForModel | null;
};

export type ModelSortingKey = 'name' | 'inspectionType' | 'imageCount' | 'createdAt';
